import { Icon } from "@chakra-ui/react";

export const SquareASVGIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 1C13.5938 1 14.5 1.90625 14.5 3V13C14.5 14.125 13.5938 15 12.5 15H2.5C1.375 15 0.5 14.125 0.5 13V3C0.5 1.90625 1.375 1 2.5 1H12.5ZM13 13V3C13 2.75 12.75 2.5 12.5 2.5H2.5C2.21875 2.5 2 2.75 2 3V13C2 13.2812 2.21875 13.5 2.5 13.5H12.5C12.75 13.5 13 13.2812 13 13ZM8.15625 4.40625L11.1562 10.9375C11.3438 11.3125 11.1875 11.7812 10.8125 11.9375C10.6875 12 10.5938 12.0312 10.4688 12.0312C10.1875 12.0312 9.9375 11.8438 9.8125 11.5938L9.4375 10.75H5.53125L5.15625 11.5938C5 11.9688 4.5625 12.125 4.15625 11.9375C3.78125 11.7812 3.625 11.3125 3.8125 10.9375L6.8125 4.40625C7.0625 3.875 7.90625 3.875 8.15625 4.40625ZM6.21875 9.25H8.75L7.5 6.53125L6.21875 9.25Z"
      fill="currentColor"
    />
  </svg>
);
