import { Icon } from "@chakra-ui/react";

export const ReelSVGIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 5.5V4.5H12.5V5.5H2.5ZM1.25 1.5C0.8125 1.5 0.5 1.1875 0.5 0.75C0.5 0.34375 0.8125 0 1.25 0H13.75C14.1562 0 14.5 0.34375 14.5 0.75C14.5 1.1875 14.1562 1.5 13.75 1.5H12.5V3.5H2.5V1.5H1.25ZM4 1.5V2.5H11V1.5H4ZM12.5 8.5V9.5H2.5V8.5H12.5ZM14 6.5C14.25 6.5 14.5 6.75 14.5 7C14.5 7.28125 14.25 7.5 14 7.5H2.5V6.5H14ZM13.75 14.5C14.1562 14.5 14.5 14.8438 14.5 15.25C14.5 15.6875 14.1562 16 13.75 16H1.25C0.8125 16 0.5 15.6875 0.5 15.25C0.5 14.8438 0.8125 14.5 1.25 14.5H2.5V12.5H12.5V14.5H13.75ZM11 14.5V13.5H4V14.5H11ZM2.5 11.5V10.5H12.5V11.5H2.5Z"
      fill="currentColor"
    />
  </svg>
);
