import React, { useContext } from "react";
import { Flex, Link, Icon, Text, useColorModeValue, Box, Tag, useColorMode, Button, IconButton, BoxProps } from "@chakra-ui/react";
import NextLink from "next/link";
import { IconType } from "react-icons/lib";
import { useRouter } from "next/router";
import { TweetContext } from "../../context/tweetContext";
import { color } from "theme/names";
import { colors } from "theme/colors/colors";
import { hexToHexa, hslToHsla } from "utils/helpers";

const NavItemContent = ({
  handleNavItemClick,
  isNotInUserPlan,
  icon,
  iconColor,
  navSize,
  ai,
  accordion,
  isActive,
  title,
  isInGrowPlan,
  isInEnterprisePlan,
  crm,
  affiliate
}) => {
  return (
    <Flex
      onClick={handleNavItemClick}
      alignItems={"center"}
      fontWeight="500"
      h="36px"
      color={iconColor}
    >
      {/* {icon && (
        <Icon
          viewBox="0 0 20 17"
          color={iconColor}
        >
          {icon}
        </Icon>
      )} */}
      {icon}
      <Box
        ml={navSize == "small" ? "0" : "3"}
        display={{ base: "flex", md: "grid" }}
        alignItems="center"
        flexWrap="wrap"
      >
        <Box
          {...{
            fontSize: "sm",
            whiteSpace: "nowrap",
            display: navSize == "small" ? "none" : "flex",
            mr: { base: "3", md: "0" },
            lineHeight: "1.3",
            zIndex: "10",
            bgGradient: (() => {
              if (ai && isActive)
                return "linear(to-br, #EC6181 0%, #316BFF 100%)"; // if active and accordion, then we want the gradient
              if (crm && isActive)
                return `linear(to-br, ${colors.crm.lightMode[250]} 0%, ${colors.crm.lightMode[350]} 100%)`; // if active and accordion, then we want the gradient
              if (isActive)
                return `linear(to-br, ${colors.primary.lightMode[250]} 0%, ${colors.primary.lightMode[350]} 100%)`; // if active and accordion, then we want the gradient
              return undefined; // if not active and accordion, then we want the text color to come from the color prop
            })(),
            bgClip: (() => {
              if (isActive) return "text"; // if active and accordion, then we want the gradient
              return undefined; // if not active and accordion, then we want the text color to come from the color prop
            })(),
            _dark: {
              bgGradient: (() => {
                if (ai && accordion && isActive)
                  return "linear(to-br, hsl(346.2, 78.5%, 70%) 0%, hsl(223.1, 100%, 69.6%) 100%)"; // if active and accordion, then we want the gradient
                if (crm && accordion && isActive)
                  return `linear(to-br, ${colors.crm.darkMode[400]} 0%, ${colors.crm.darkMode[200]} 100%)`; // if active and accordion, then we want the gradient
                if (!ai && accordion && isActive)
                  return `linear(to-br, ${colors.primary.darkMode[480]} 0%, ${colors.primary.darkMode[450]} 100%)`; // if active and accordion, then we want the gradient
                return undefined; // if not active and accordion, then we want the text color to come from the color prop
              })(),
            }
          }}
        >
          {title}
        </Box>
        {/* {title==="AI Writer" || title==="Contacts" && ( */}
        {isInGrowPlan && (
          <Tag
            color="black"
            bg={colors.warning.lightMode[200]}
            size="xs"
            fontSize="8px"
            px={1}
            py={0.5}
            fontWeight="bolder"
            display={navSize == "small" ? "none" : "inline-flex"}
            placeSelf="start"
          >
            GROW
          </Tag>
        )}
        {/* {true && ( */}
        {isInEnterprisePlan && (
          <Tag
            color="black"
            bg={colors.warning.lightMode[200]}
            size="xs"
            fontSize="8px"
            px={1.5}
            py={0.5}
            fontWeight="bolder"
            display={navSize == "small" ? "none" : "inline-flex"}
            placeSelf="start"
          >
            ENTERPRISE
          </Tag>
        )}
      </Box>
    </Flex>
  )
}

interface Props {
  title: string | React.ReactElement;
  href?: string;
  target?: string;
  navSize: string;
  icon?: any;
  // icon?: IconType | typeof Icon;
  children?: React.ReactElement;
  iconStroke?: string;
  isInGrowPlan?: boolean;
  isInEnterprisePlan?: boolean;
  matchPath?: string;
  accordion?: boolean;
  ai?: boolean;
  crm?: boolean;
  lib?: boolean;
  affiliate?: boolean;
  onClick?: () => void;
  display?: BoxProps["display"];
}

export function NavItem({
  title,
  href,
  target,
  onClick,
  icon,
  navSize,
  display,
  iconStroke = "2",
  isInGrowPlan = false,
  isInEnterprisePlan = false,
  matchPath = "",
  accordion = false,
  ai = false,
  crm = false,
  lib = false,
  affiliate = false,
}: Props) {
  const router = useRouter();
  const isActive = href && router.asPath.includes(matchPath || href);
  const tweetContext: any = useContext(TweetContext);

  const { colorMode } = useColorMode();
  const marginLeftWidthInPixels = accordion ? `28px` : "0px";

  let buttonContainerProps = {
    background: (() => {
      if (navSize === "large") {
        if (isActive) {
          if (ai) return colors.ai[50]
          if (crm) return colors.crm.lightMode[20]
          return colors.primary.lightMode[90];
        }
      }
      return "transparent";
    })(),
    border: (() => {
      if (affiliate && navSize === "large") return `1px solid ${colors.primary.lightMode[100]}`
      return undefined
    })(),
    color: (() => { // text label color
      if (accordion) {
        if (isActive) {
          return undefined // gradient handles color for active accordion items
        }
        if (!isActive) {
          if (ai) return `${colors.ai[600]}`
          if (crm) return `${colors.crm.lightMode[300]}`
          if (lib) return `${colors.primary.lightMode[350]}`
          return "text.lightMode.light"
        }
      }
      if (!accordion) {
        if (affiliate) return colors.primary.lightMode[300]
        if (isActive) {
          return `${colors.primary.lightMode[350]}`
        }
        if (!isActive) {
          return "text.lightMode.light"
        }
      }
      return undefined;
    })(),
    _hover: {
      color: (() => {
        if (isActive) return undefined // gradient handles color for active accordion items
        if (!isActive) {
          if (ai) return `${colors.ai[700]}`
          if (crm) return `${colors.crm.lightMode[350]}`
          if (affiliate) return colors.primary.lightMode[300]
          return colors.primary.lightMode[350]
        }
        return undefined
      })(),
      filter:
        ai && accordion && isActive && colorMode === "light"
          ? "brightness(1.015)"
          : undefined,
      bg: (() => {
        if (ai && accordion && colorMode === "light") return colors.ai[50]
        if (crm && accordion && colorMode === "light") return colors.crm.lightMode[20]
        return colors.primary.lightMode[90]
      })(),
    },
    _dark: {
      background: (() => {
        if (navSize === "large") {
          if (isActive) {
            if (ai) return hexToHexa(colors.ai[900], 0.2)
            if (crm) return hslToHsla(colors.crm.darkMode[40], 0.2)
            return hslToHsla(colors.primary.darkMode[105], 0.3)
          }
        }
        return "transparent";
      })(),
      color: (() => { // text label color
        if (accordion) {
          if (isActive) {
            return undefined // gradient handles color for active accordion items
          }
          if (!isActive) {
            if (ai) return `${colors.ai[400]}`
            if (crm) return hslToHsla(`${colors.crm.darkMode[250]}`, 0.8)
            if (lib) return `${colors.primary.darkMode[300]}`
            return "text.darkMode.light"
          }
        }
        if (!accordion) {
          if (isActive) {
            return `${colors.primary.darkMode[480]}`
          }
          if (!isActive) {
            return "text.darkMode.light"
          }
        }
        return undefined;
      })(),
      _hover: {
        color: (() => {
          if (accordion) {
            if (isActive) return undefined // gradient handles color for active accordion items
            if (!isActive) {
              if (ai) return `${colors.ai[200]}`
              if (crm) return hslToHsla(`${colors.crm.darkMode[300]}`, 0.8)
              return colors.primary.darkMode[480]
            }
          }
          if (!accordion) {
            return `${colors.primary.darkMode[480]}`
          }
          return undefined
        })(),
        background: (() => {
          if (ai) return hexToHexa(colors.ai[900], 0.2)
          if (crm) return hslToHsla(colors.crm.darkMode[40], 0.2);
          return hslToHsla(colors.primary.darkMode[105], 0.3)
        })(),
        filter: isActive ? "brightness(1.15)" : undefined,
      },
    },
    fontWeight: (() => {
      if (isActive) return "600";
      return "500";
    })(),
    h: "36px",
    pl: accordion
      ? navSize === "small"
        ? "0"
        : "4"
      : navSize === "small"
        ? "0"
        : "3",
    ml: accordion ? marginLeftWidthInPixels : "0",
    pr: accordion ? "4" : "0",
    w: navSize == "large" ? "100%" : "auto",
    borderRadius: affiliate ? "4px" : "4px",
  };

  const handleNavItemClick = () => {
    const { isFullModeOpen, setIsFullModeOpen, setTextState, refComposer } = tweetContext;
    if (isFullModeOpen) {
      setTextState(refComposer.current.textState());
      setIsFullModeOpen(false);
    }
  };

  const isNotInUserPlan = isInGrowPlan || isInEnterprisePlan;

  const iconColor = useColorModeValue(
    (() => {
      if (isActive) {
        if (ai) return "#CC6397"
        if (crm) return `${colors.crm.lightMode[250]}`
        return colors.primary.lightMode[260]
      }
      return undefined
    })(),
    (() => {
      if (isActive) {
        if (ai) return "hsl(330.3, 50.7%, 70%)"
        if (crm) return `${colors.crm.darkMode[400]}`
        return colors.primary.darkMode[450]
      }
      return undefined
    })()
    // isActive ? "primary.lightMode.200" : undefined // dark mode
  );

  return (
    <Flex w="full" alignItems="center" justifyContent="center" onClick={onClick} display={display}>
      {/* <NextLink href={href} passHref legacyBehavior> */}

      {href && (
        <Link
          href={href}
          as={NextLink}
          {...buttonContainerProps}
          target={target}
        >
          <NavItemContent
            {...{
              handleNavItemClick,
              isNotInUserPlan,
              icon,
              iconColor,
              navSize,
              ai,
              accordion,
              isActive,
              title,
              isInGrowPlan,
              isInEnterprisePlan,
              crm,
              affiliate
            }}
          />
        </Link>
      )}
      {!href && (
        <Box {...buttonContainerProps} cursor="pointer">
          <NavItemContent
            {...{
              handleNavItemClick,
              isNotInUserPlan,
              icon,
              iconColor,
              navSize,
              ai,
              accordion,
              isActive,
              title,
              isInGrowPlan,
              isInEnterprisePlan,
              crm,
              affiliate
            }}
          />
        </Box>
      )}
      {/* </NextLink> */}
    </Flex>
  );
}
