import { useState, useEffect, memo } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Icon,
  useColorModeValue,
  Text,
  Box,
  useColorMode
} from "@chakra-ui/react";
import { BsChevronDown, BsChevronRight, BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import { IconType } from "react-icons/lib";
import { hexToHexa, hslToHsla, setInLocalStorage } from "utils/helpers";
import { color } from "theme/names";
import { motion } from "framer-motion";
import { colors } from "theme/colors/colors";
import { is } from "date-fns/locale";

interface Props {
  navSize: "large" | "small";
  icon: any;
  iconExpanded: any;
  title: string;
  children: React.ReactNode;
  isInGrowPlan?: boolean;
  expanded?: boolean
  setExpanded?: (val: boolean) => void
  ai?: boolean
  crm?: boolean
  up?: boolean
}

export function AccordianNav({
  navSize,
  icon,
  iconExpanded = <></>,
  title,
  children,
  isInGrowPlan = false,
  expanded,
  setExpanded,
  ai = false,
  crm = false,
  up = false,
}: Props) {
  const [expandedIndex, setExpandedIndex] = useState<any>([]);
  const key = "acc_" + title.toLowerCase().replace(" ", "_");

  useEffect(() => {
    const savedState = localStorage.getItem(key);
    if (savedState) {
      setExpandedIndex(JSON.parse(savedState));
    }
    // in case the accordion needs more time to expand (not sure it really has any impact)
    // const timeoutID = setTimeout(() => {
    //   if (savedState) {
    //     setExpandedIndex(JSON.parse(savedState));
    //   }
    // }, 500);

    // return () => {
    //   clearTimeout(timeoutID);
    // }
    
  }, [navSize]);

    // hoverState
    const [buttonHoverState, setButtonHoverState] = useState(false);
    const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Accordion
      w="100%"
      mx="4"
      allowMultiple
      index={navSize !== "small" ? expandedIndex : []}
      // index={[0]}
      // defaultIndex={[0]}
      onChange={(ei) => {
        // console.log("ei");
        // console.log(ei);
        setExpandedIndex(ei);
        setInLocalStorage(key, JSON.stringify(ei));
      }}
      overflow="hidden"
    >
      <AccordionItem border="0" display="flex" justifyContent="center">
        {({ isExpanded }) => (
          <Box w="100%">
            <AccordionButton
              {...{
                // background with a gradient
                _before: {
                  content: '""', // required for visibility
                  bgGradient: (() => {
                    if (isExpanded) {
                      if (ai)
                        return "linear(to-br, #EC618116 0%, #316BFF16 100%)";
                      if (crm)
                        return `linear(to-br, ${colors.crm.lightMode[20]} 0%, ${colors.crm.lightMode[30]} 100%)`;
                      return `linear(to-br, ${colors.primary.lightMode[90]} 0%, ${colors.primary.lightMode[110]} 100%)`;
                    }
                    if (!isExpanded) return undefined;
                    return undefined;
                  })(),
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  borderRadius: "9px",
                },
                filter: (() => {
                  if(isExpanded && ai) return "brightness(1.15)";
                  return undefined;
                })(),
                color: isExpanded ? undefined : "text.lightMode.light",
                _hover: {
                  filter: (() => {
                    if (isExpanded) {
                      if(ai) return "brightness(1.25)";
                      return "brightness(1.0125)";
                    }
                    if (!isExpanded) {
                      // if(ai) return "brightness(1.25)";
                      return "brightness(1.0125)";
                    }
                    return undefined;
                  })(),
                  color: (() => {
                    if (ai) return colors.ai[700];
                    if (crm) return colors.crm.lightMode.default;
                    return colors.primary.lightMode[350];
                  })(),
                  bg: (() => {
                    if (isExpanded) return undefined;
                    if (!isExpanded) {
                      if (ai) return colors.ai.lightMode[10];
                      if (crm) return colors.crm.lightMode[15];
                      return colors.primary.lightMode[90];
                    }
                    return undefined;
                  })(),
                },
                _dark: {
                  // background with a gradient
                  _before: {
                    content: '""', // required for visibility
                    bgGradient: (() => {
                      if (isExpanded) {
                        if (ai)
                          return "linear(to-br, #EC618155 0%, #316BFF55 100%)";
                        if (crm)
                          return `linear(to-br, ${hslToHsla(
                            colors.crm.darkMode[100],
                            0.4
                          )} 0%, ${hslToHsla(
                            colors.crm.darkMode[50],
                            0.4
                          )} 100%)`;
                        return `linear(to-br, ${hslToHsla(
                          colors.primary.darkMode[250],
                          0.4
                        )} 0%, ${hslToHsla(
                          colors.primary.darkMode[210],
                          0.4
                        )} 100%)`;
                      }
                      if (!isExpanded) return undefined;
                      return undefined;
                    })(),
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    borderRadius: "9px",
                  },
                  bgGradient: undefined,
                  bgClip: undefined,
                  color: isExpanded
                    ? color["text.darkMode.standard"]
                    : color["text.darkMode.light"],
                  _hover: {
                    filter: (() => {
                      if (isExpanded) {
                        if(ai) return "brightness(1.25)";
                        return "brightness(1.0125)";
                      }
                      return undefined;
                    })(),
                    color: (() => {
                      if (ai) return colors.ai[400];
                      if (crm) return colors.crm.darkMode.default;
                      return colors.primary.darkMode[480];
                    })(),
                    bg: (() => {
                      if (isExpanded) return undefined;
                      if (!isExpanded) {
                        if (ai) return hexToHexa(colors.ai[900], 0.2)
                        if (crm) return hslToHsla(colors.crm.darkMode[40], 0.4);
                        return hslToHsla(colors.primary.darkMode[105], .3)
                      }
                      return undefined;
                    })(),
                  },
                },
                position: "relative",
                px: navSize === "large" ? "3" : "0",
                py: "2",
                borderRadius: "8",
                w: navSize == "large" ? "100%" : "auto",
                h: "40px",
                display: "flex",
                alignItems: "center",
                fontWeight: isExpanded ? "600" : "500",
                fontSize: "sm",
                mx: "auto",
                justifyContent: navSize === "small" ? "center" : "left",
                _focus: { boxShadow: "none" },
                onMouseEnter: () => setButtonHoverState(true),
                onMouseLeave: () => setButtonHoverState(false),
                onClick: () => setExpanded && setExpanded(!expanded),
              }}
            >
              <Icon
                {...{
                  viewBox: "0 0 16 17",
                  color: (() => {
                    if (isExpanded) {
                      if (crm) return colors.crm.lightMode[250];
                      return colors.primary.lightMode[260];
                    }
                    return undefined;
                  })(),
                  _dark: {
                    color: (() => {
                      if (isExpanded) {
                        if (crm) return colors.crm.darkMode[300];
                        return colors.primary.darkMode[450];
                      }
                      return undefined;
                    })(),
                  },
                  style: {
                    zIndex: 0,
                  },
                }}
              >
                {isExpanded ? iconExpanded : icon}
              </Icon>
              <Text
                {...{
                  ml: navSize === "large" ? "3" : "0",
                  display: navSize == "small" ? "none" : "flex",
                  whiteSpace: "nowrap",
                  fontSize: "sm",
                  flex: "1",
                  zIndex: 1,
                  // when expanded we want to color text with bgGradient + bgClip, when not expanded we want to use color
                  bgGradient: (() => {
                    if (isExpanded) {
                      if (ai) return "linear(to-br, #EC6181 0%, #316BFF 70%)";
                      if (crm)
                        return `linear(to-br, ${colors.crm.lightMode[200]} 0%, ${colors.crm.lightMode[350]} 50%)`;
                      return `linear(to-br, ${colors.primary.lightMode[200]} 0%, ${colors.primary.lightMode[350]} 100%)`;
                    }
                    if (!isExpanded) return undefined;
                    return undefined;
                  })(),
                  bgClip: isExpanded ? "text" : undefined,
                  _dark: {
                    bgGradient: (() => {
                      if (isExpanded) {
                        if (ai)
                          return "linear(to-br, hsl(346.2, 78.5%, 70%) 0%, hsl(223.1, 100%, 70%) 100%)";
                        if (crm)
                          return `linear(to-br, ${colors.crm.darkMode[400]} 0%, ${colors.crm.darkMode[200]} 50%)`;
                        return `linear(to-br, ${colors.primary.darkMode[480]} 0%, ${colors.primary.darkMode[390]} 100%)`;
                      }
                      if (!isExpanded) return undefined;
                      return undefined;
                    })(),
                    bgClip: isExpanded ? "text" : undefined,
                  },
                }}
              >
                {title}
              </Text>
              {/* {title === "AI Writer" && ( */}
              {isInGrowPlan && (
                <Text
                  color="black"
                  bg={colors.warning.lightMode[200]}
                  fontWeight="bolder"
                  borderRadius="md"
                  mr="2"
                  size="xs"
                  fontSize="8px"
                  px={1}
                  py={0.5}
                  display={navSize == "small" ? "none" : "flex"}
                >
                  GROW
                </Text>
              )}
              {/* rotating caret icon */}
              <motion.div
                animate={{
                  rotate: isExpanded ? 180 : 0,
                }}
                transition={{ type: "spring", duration: 0.5, bounce: 0 }}
              >
                <Icon
                  {...{
                    as: up ? BsFillCaretUpFill : BsFillCaretDownFill,
                    color: (() => {
                      if (isExpanded) {
                        if (ai) return "ai.500";
                        if (crm) return colors.crm.lightMode.default;
                        return `${colors.primary.lightMode[350]}`;
                      }
                      if (buttonHoverState) {
                        if (ai) return colors.ai[700];
                        if (crm) return colors.crm.lightMode.default;
                        return colors.primary.lightMode[350];
                      }
                      return "text.lightMode.light";
                    })(),
                    opacity: (()=>{
                      // if (buttonHoverState) {
                        // if (ai) return 1;
                        // if (crm) return 1;
                        return 0.66
                      // }
                      return 1
                    })(),
                    // !isExpanded && !buttonHoverState
                    // _hover: {
                    //   color: `${colors.primary.lightMode[350]}`
                    // },
                    _dark: {
                      color: (() => {
                        if (isExpanded) {
                          if (ai) return "ai.500";
                          if (crm) return colors.crm.lightMode.default;
                          return hslToHsla(colors.primary.darkMode[390], 0.6);
                        }
                        if (buttonHoverState) {
                          // if (ai) return colors.ai[700];
                          // if (crm) return colors.crm.lightMode.default;
                          // return colors.primary.lightMode[350];
                        }
                        return "text.darkMode.light";
                      })(),
                      // color: isExpanded
                      //   ? buttonHoverState
                      //     ? "ai.200" // isExpanded && buttonHoverState
                      //     : "ai.300" // isExpanded && !buttonHoverState
                      //   : buttonHoverState
                      //   ? "text.darkMode.standard" // !isExpanded && buttonHoverState
                      //   : "text.darkMode.light", // !isExpanded && !buttonHoverState
                    },
                    display: navSize == "small" ? "none" : "flex",
                  }}
                />
              </motion.div>
            </AccordionButton>
            <AccordionPanel
              {...{
                position: "relative",
                p: "0",
                mt: 1,
                // blue line
                _before: {
                  mt: -1,
                  content: '""', // required for visibility
                  background: (() => {
                    if (ai) return colors.ai[50];
                    if (crm) return colors.crm.lightMode[20];
                    return colors.primary.lightMode[90];
                  })(),
                  // background: ai ? colors.ai[50] : colors.primary.lightMode[90],
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "2px",
                  height: "100%",
                  ml: "28px",
                },
                _dark: {
                  _before: {
                    content: '""', // required for visibility
                    background: color["border.darkMode.light"],
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "2px",
                    height: "100%",
                    ml: "28px",
                  },
                },
              }}
            >
              {children}
            </AccordionPanel>
          </Box>
        )}
      </AccordionItem>
    </Accordion>
  );
}

export const AccordianNavMemoed = memo(AccordianNav);