import {
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Text,
  VStack,
  useColorModeValue,
  useMediaQuery,
} from "@chakra-ui/react";
import { useWindowWidth } from "@react-hook/window-size";
import { useChatContext } from "context/chatContext";
import { useTweetContext } from "context/tweetContext";
import { isAllowed } from "controllers/subscription";
import { useSession } from "next-auth/client";
import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { FiTwitter } from "react-icons/fi";
import { GiPocketBow } from "react-icons/gi";
import { MdOutlineArrowBack } from "react-icons/md";
import { variant } from "theme/names";
import { AccordianNavMemoed } from "./accordianNav";
import { AtSVGIcon } from "./icons/AtSVGIcon";
import { CalendarSVGIcon } from "./icons/CalendarSVGIcon";
import { CircleUserSVGIcon } from "./icons/CircleUserSVGIcon";
import { CollabSVGIcon } from "./icons/CollabSVGIcon";
import { ContactCardSVGIcon } from "./icons/ContactCardSVGIcon";
import { ContactSVGIcon } from "./icons/ContactSVGIcon";
import { GeneratorsAIExpandedSVGIcon } from "./icons/GeneratorsAIExpandedSVGIcon";
import { GeneratorsAISVGIcon } from "./icons/GeneratorsAISVGIcon";
import { InspirationSVGIcon } from "./icons/InspirationSVGIcon";
import { LibrarySVGIcon } from "./icons/LibrarySVGIcon";
import { LocationSVGIcon } from "./icons/LocationSVGIcon";
import { MyStatsSVGIcon } from "./icons/MyStatsSVGIcon";
import { ProfileAutomationsSVGIcon } from "./icons/ProfileAutomationsSVGIcon";
import { PlaySVGIcon } from "./icons/PlaySVGIcon";
import { ReelSVGIcon } from "./icons/ReelSVGIcon";
import { SearchModeSVGIcon } from "./icons/SearchModeSVGIcon";
import { SquareASVGIcon } from "./icons/SquareASVGIcon";
import { SquareUserSVGIcon } from "./icons/SquareUserSVGIcon";
import { StarSVGIcon } from "./icons/StarSVGIcon";
import { TreeSVGIcon } from "./icons/TreeSVGIcon";
import { WriteAPostSVGIcon } from "./icons/WriteAPostSVGIcon";
import { NavItem } from "./navItem";
import { AiButton, AiIconButton } from "components/common/AiButton/AiButton";
import { FilmImageIcon } from "./icons/FilmImageIcon";

interface Props {
  navSize: "small" | "large";
  isMinimalist: boolean;
  changeNavSize: (val: "small" | "large") => void;
  setWithShadowFooter: Dispatch<SetStateAction<boolean>>;
  expandedAccordion1;
  setExpandedAccordion1;
  expandedAccordion2;
  setExpandedAccordion2;
  expandedAccordion3;
  setExpandedAccordion3;
  expandedAccordionHelp;
  setExpandedAccordionHelp;
}

export function NavSectionTitle({
  title,
  navSize,
  compact = false,
  ...props
}: {
  title: string;
  navSize: "small" | "large";
  compact?: boolean;
  [props: string]: any;
}) {
  return (
    <Box
      {...{
        mt: compact ? 1 : 6,
        mb: compact ? 1 : 2,
        ml: compact ? 1 : 2,
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "11px",
        lineHeight: "20px",
        letterSpacing: "1.5px",
        color: "#98A1AC",
        ...props,
      }}
    >
      {navSize === "large" && title}
      {navSize === "small" && <Box h={5} />}
    </Box>
  );
}

export function MainNavItems({
  navSize,
  isMinimalist,
  changeNavSize,
  setWithShadowFooter,
  expandedAccordion1,
  setExpandedAccordion1,
  expandedAccordion2,
  setExpandedAccordion2,
  expandedAccordion3,
  setExpandedAccordion3,
  expandedAccordionHelp,
  setExpandedAccordionHelp,
}: Props) {
  const [session] = useSession();

  let lastCollaborationMode = "client";
  if (typeof window !== "undefined") {
    lastCollaborationMode =
      localStorage.getItem("collaboration_last_mode") || "client";
  }

  const [isTooSmall] = useMediaQuery("(max-width: 800px)");
  const tweetContext: any = useTweetContext();
  const chatContext: any = useChatContext();

  const schedulerWidth = 400;
  const leftSideNavWidth = 60;
  const screenWidth = useWindowWidth();
  const chatAssistPopupMaxWidth = Math.min(900, screenWidth);
  const wontFit =
    schedulerWidth + leftSideNavWidth + chatAssistPopupMaxWidth > screenWidth;

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      // @ts-ignore
      if (ref.current?.scrollHeight && ref.current?.clientHeight) {
        // scrollHeight is the height of the content, including content that is not visible on the screen due to overflow.
        // clientHeight is the height of the content, not including content that is not visible on the screen due to overflow. It acts as the height of the container of the overflowed content.
        // @ts-ignore
        setWithShadowFooter(
          ref.current?.scrollHeight > ref.current?.clientHeight
        );
      }
    }, 320); // timeout for the accordion to expand and collapse
  }, [
    expandedAccordion1,
    expandedAccordion2,
    expandedAccordion3,
    expandedAccordionHelp,
    navSize,
  ]);

  const glowIcon = useColorModeValue(true, false);

  if (isMinimalist)
    return (
      <Flex justifyContent="center" w="100%" mt="3">
        {navSize === "small" ? (
          <IconButton
            variant={variant.Button.primary}
            w="40px"
            aria-label="Write a tweet"
            icon={session?.user?.uid ? <MdOutlineArrowBack /> : <GiPocketBow />}
          />
        ) : (
          <Button
            variant={"primary"}
            w={navSize == "large" ? "85%" : "auto"}
            leftIcon={
              session?.user?.uid ? <MdOutlineArrowBack /> : <GiPocketBow />
            }
            p={navSize == "large" ? "4" : "0"}
            mx="auto"
            as="a"
            href={"https://app.tweethunter.io/subscribe"}
          >
            <Text>
              {session?.user?.uid
                ? "Back to Tweet Hunter"
                : "Join Tweet Hunter"}
            </Text>
          </Button>
        )}
      </Flex>
    );

  return (
    <Box
      // w="100%"
      // flexDirection="column"
      // h="calc(100% - 350px)"
      // overflow="hidden auto"
      // className="noScrollBar"
      // overscrollBehavior="contain"
      ref={ref}
      w="100%"
      flexDirection="column"
      flex="1"
      overflow="hidden auto"
      className="noScrollBar"
      overscrollBehavior={"contain"}
      px={navSize === "small" ? "2" : "3"}
      pb={3}
    >
      <VStack spacing={"1"}>
        <Flex
          justifyContent="center"
          alignItems={navSize === "small" ? "center" : ""}
          w="100%"
          mt="6"
          mb="4"
          flexDirection="column"
          gap="14px"
        >
          {navSize === "small" ? (
            <IconButton
              variant={variant.Button.primary}
              w="40px"
              aria-label="Write a tweet"
              icon={<WriteAPostSVGIcon />}
            />
          ) : (
            <Button
              variant={variant.Button.primary}
              leftIcon={<WriteAPostSVGIcon />}
              p={navSize == "large" ? "4" : "0"}
              mx={navSize == "large" ? "3" : "auto"}
              onClick={() => {
                if (isTooSmall && chatContext?.isOpen) {
                  chatContext?.onClose();
                }
                tweetContext.open();
                changeNavSize("small");
              }}
            >
              Write a tweet
            </Button>
          )}
          {isAllowed(session?.user, "chat") && navSize === "small" && (
            // <AnimatedBg
            //   {...{
            //     w: "40px",
            //     h: "40px",
            //     square: true,
            //   }}
            // >
            //   <IconButton
            //     variant={variant.Button.secondaryAI}
            //     w="40px"
            //     {...{
            //       border: "1px solid #A376BB22",
            //       bg: "#A577dd08",
            //       _dark: {
            //         borderColor: "#403278",
            //       },
            //     }}
            //     aria-label="Open chat assist"
            //     icon={<AiIcon {...{ glow: glowIcon }} />}
            //   />
            // </AnimatedBg>
            <AiIconButton />
          )}
          {isAllowed(session?.user, "chat") && navSize === "large" && (
            <AiButton {...{
              onClick: () => {
                if (tweetContext?.isOpen && wontFit) {
                  tweetContext?.close();
                }
                if (chatContext?.isOpen) chatContext?.onClose();
                else chatContext?.onOpen();
                changeNavSize("small");
              },
            }}>Chat Assist</AiButton>
            // <AnimatedBg
            //   {...{
            //     mx: navSize == "large" ? "3" : "auto",
            //     h: "40px",
            //   }}
            // >
            //   <Button
            //     {...{
            //       background: "transparent",
            //       fontSize: "sm",
            //       bgGradient: "linear(to-b, #EC6181 25%, #316BFF 80%)",
            //       bgClip: "text",
            //       filter: "brightness(1.15)",
            //       _hover: {
            //         // filter: "brightness(1.25)",
            //         bgGradient: "linear(to-b, #EC6181 35%, #316BFF 75%)",
            //         bgClip: "text",
            //       },
            //       _active: {
            //         color: "#A577dd",
            //       },
            //       leftIcon: <AiIcon {...{ glow: glowIcon }} />,
            //       p: navSize == "large" ? "4" : "0",
            //       onClick: () => {
            //         if (tweetContext?.isOpen && wontFit) {
            //           tweetContext?.close();
            //         }
            //         if (chatContext?.isOpen) chatContext?.onClose();
            //         else chatContext?.onOpen();
            //         changeNavSize("small");
            //       },
            //     }}
            //   >
            //     Chat Assist
            //   </Button>
            // </AnimatedBg>
          )}
        </Flex>

        {/* // <Flex justifyContent="center" w="100%" mt="3">
          //   {navSize === "small" ? (
          //     <GradientButtonIcon />
          //   ) : (
          //     <GradientButton
          //       // w={navSize == "large" ? "85%" : "auto"}
          //       w="full"
          //       onClick={() => {
          //         if (tweetContext?.isOpen && wontFit) {
          //           tweetContext?.close();
          //         }
          //         if (chatContext?.isOpen) chatContext?.onClose();
          //         else chatContext?.onOpen();
          //         changeNavSize("small");
          //       }}
          //     >
          //       <Text>AI Assistant</Text>
          //     </GradientButton>
          //   )}
          // </Flex> */}

        {/* <NavItem
          title="My Dashboard"
          href="/dashboard"
          icon={FiHome}
          navSize={navSize}
        /> */}
        <NavItem
          title="Queue"
          href="/queue?tab=schedule"
          icon={CalendarSVGIcon}
          navSize={navSize}
          matchPath="/queue"
        />
        <NavItem
          title="Analytics"
          href="/stats"
          icon={MyStatsSVGIcon}
          navSize={navSize}
        />
        <NavItem
          title="Profile Automations"
          href="/profile-automations"
          icon={ProfileAutomationsSVGIcon}
          navSize={navSize}
        />
        {/* // session?.user?.data?.isAdmin && ( */}
        <NavItem
          title={
            <Text as="span">
              Collaboration{" "}
              {/* <Text as="span" fontSize="xs">
                (beta)
              </Text> */}
            </Text>
          }
          href={"/collaboration?tab=" + lastCollaborationMode}
          // icon={AiOutlineTeam}
          icon={CollabSVGIcon}
          navSize={navSize}
        />
        {/* {
          (session?.user?.data?.ghostwriterData) && (
            <NavItem
              title={(<Text as="span">Ghostwriter Profile <Text as="span" fontSize="xs">(beta)</Text></Text>)}
              href="/ghostwriter"
              icon={FiFeather}
              navSize={navSize}
            />
          )
        } */}
        {/* {
          session?.user?.data?.isAdmin && (
            <NavItem
              title={(<Text as="span">Ghostwriting <Text as="span" fontSize="xs">(beta)</Text></Text>)}
              href="/ghostwriting"
              icon={AiOutlineStar}
              navSize={navSize}
            />
          )
        } */}
      </VStack>
      <Divider
        my="4"
        w={`calc(100% - ${navSize === "large" ? "24px" : "24px"})`}
        mx="auto"
      />

      {/* <NavSectionTitle {...{ title: "CONTENT INSPIRATION", navSize }} /> */}

      <VStack spacing="1">
        <NavItem
          title="Daily Inspiration"
          href="/auto"
          icon={InspirationSVGIcon}
          navSize={navSize}
        />
        <AccordianNavMemoed
          icon={LibrarySVGIcon}
          iconExpanded={LibrarySVGIcon}
          navSize={navSize}
          title="Post Creation"
          {...{
            expanded: expandedAccordion1,
            setExpanded: setExpandedAccordion1,
          }}
        >
          <VStack spacing="1">
            <NavItem
              title="Search"
              href="/search"
              icon={SearchModeSVGIcon}
              navSize={navSize}
              accordion
              lib
            />
            <NavItem
              title="My Collections"
              href="/collections?tab=collections"
              icon={StarSVGIcon}
              navSize={navSize}
              accordion
              lib
            />
            <NavItem
              title="Evergreen"
              href="/collections?tab=evergreen"
              icon={TreeSVGIcon}
              navSize={navSize}
              accordion
              lib
            />
            <NavItem
              title="My assets"
              href="/medias"
              icon={FilmImageIcon}
              navSize={navSize}
              accordion
              lib
            />
          </VStack>
        </AccordianNavMemoed>
        <AccordianNavMemoed
          icon={<GeneratorsAISVGIcon />}
          iconExpanded={GeneratorsAIExpandedSVGIcon}
          navSize={navSize}
          title="AI Writer"
          isInGrowPlan={!isAllowed(session?.user, "generate")}
          {...{
            expanded: expandedAccordion2,
            setExpanded: setExpandedAccordion2,
          }}
          ai
        >
          <VStack spacing="1">
            <NavItem
              title="AI Tweets"
              href="/generate"
              icon={<FiTwitter {...{ fontSize: "16px" }} />}
              navSize={navSize}
              accordion
              ai
            />
            <NavItem
              title="Thread Ideas"
              href="/idea"
              icon={ReelSVGIcon}
              navSize={navSize}
              accordion
              ai
            />
            <NavItem
              title="Thread Starters"
              href="/hook"
              icon={PlaySVGIcon}
              navSize={navSize}
              accordion
              ai
            />
            {/* <NavItem
              title="Repurpose Content"
              href="/repurpose-content"
              icon={<HiOutlineRefresh />}
              navSize={navSize}
              accordion
              ai
            /> */}
            {/* TODO: to reactivate */}
            {/* <NavItem
              title="AI Impersonate"
              href="/ai-impersonate"
              icon={RiCopyleftLine}
              navSize={navSize}
              iconStroke="0"
            /> */}
          </VStack>
        </AccordianNavMemoed>
        <AccordianNavMemoed
          icon={ContactCardSVGIcon}
          iconExpanded={ContactCardSVGIcon}
          navSize={navSize}
          title="CRM"
          {...{
            expanded: expandedAccordion3,
            setExpanded: setExpandedAccordion3,
          }}
          crm
        >
          <VStack spacing="1">
            <NavItem
              title="Contacts"
              href="/contacts"
              icon={ContactSVGIcon}
              navSize={navSize}
              iconStroke="0"
              isInGrowPlan={!isAllowed(session?.user, "crm")}
              accordion
              crm
            />
            {/* <NavItem
              title={
                <Text as="span">
                  Lead Finder{" "}
                  <Text as="span" fontSize="xs">
                    (beta)
                  </Text>
                </Text>
              }
              href="/leads"
              icon={LocationSVGIcon}
              navSize={navSize}
              isInEnterprisePlan={!isAllowed(session?.user, "leadFinder")}
              accordion
              crm
            /> */}
            <NavItem
              title="Engage (people)"
              href="/engage?tab=people"
              icon={SquareUserSVGIcon}
              navSize={navSize}
              iconStroke="0"
              accordion
              crm
            />
            <NavItem
              title="Engage (keywords)"
              href="/engage?tab=keywords"
              icon={SquareASVGIcon}
              navSize={navSize}
              iconStroke="0"
              accordion
              crm
            />
            <NavItem
              title="Engage (mentions)"
              href="/engage?tab=mentions-and-replies"
              icon={AtSVGIcon}
              navSize={navSize}
              iconStroke="0"
              accordion
              crm
            />
            {/* {
              // session?.user?.data?.isBeta && (
              true && (
                <NavItem
                  title={
                    <Text as="span">
                      Public Profile{" "}
                      <Text as="span" fontSize="xs">
                        (beta)
                      </Text>
                    </Text>
                  }
                  href="/public-profile?tab=main"
                  icon={CircleUserSVGIcon}
                  navSize={navSize}
                  iconStroke="0"
                  accordion
                  crm
                />
              )
            } */}
          </VStack>
        </AccordianNavMemoed>
      </VStack>
    </Box>
  );
}
