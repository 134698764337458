import { Icon } from "@chakra-ui/react";

export const InspirationSVGIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 14.7188V13.5H10.5L10.4688 14.7188C10.4688 14.875 10.4062 15.125 10.3125 15.25L9.78125 16.0625C9.625 16.3125 9.25 16.5 8.9375 16.5H7.03125C6.71875 16.5 6.34375 16.3125 6.1875 16.0625L5.65625 15.25C5.53125 15.0938 5.5 14.9062 5.5 14.7188ZM8 0.5C11.0312 0.53125 13.5 2.96875 13.5 6C13.5 7.40625 12.9688 8.65625 12.125 9.59375C11.5938 10.1875 10.8125 11.4375 10.5 12.4688C10.5 12.4688 10.5 12.4688 10.5 12.5H5.46875C5.46875 12.4688 5.46875 12.4688 5.46875 12.4688C5.15625 11.4375 4.375 10.1875 3.84375 9.59375C3 8.625 2.5 7.375 2.5 6C2.5 3.09375 4.8125 0.53125 8 0.5ZM11 8.65625C11.625 7.90625 12 6.96875 12 6C12 3.8125 10.1875 2 7.96875 2C5.5 2.03125 4 4.0625 4 6C4 6.96875 4.34375 7.90625 4.96875 8.65625C5.46875 9.1875 6.0625 10.0938 6.5 11H9.46875C9.90625 10.0938 10.5 9.1875 11 8.65625ZM7.5 3C7.75 3 8 3.25 8 3.53125C8 3.78125 7.75 4 7.5 4C6.65625 4 6 4.6875 6 5.5C6 5.78125 5.75 6 5.5 6C5.21875 6 5 5.78125 5 5.5C5 4.125 6.09375 3 7.5 3Z"
      fill="currentColor"
    />
  </svg>
);
