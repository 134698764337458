import { Icon } from "@chakra-ui/react";

export const AtSVGIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.71875 0.53125C12.7188 0.625 16 4.15625 16 8.34375V8.875C16 10.4688 14.6875 11.75 13.125 11.75C12.0938 11.75 11.2188 11.2188 10.6875 10.4375C10.0625 11.0625 9.1875 11.4688 8.25 11.4688C6.3125 11.4688 4.75 9.875 4.75 8C4.75 6.09375 6.3125 4.5 8.25 4.5C9 4.5 9.6875 4.75 10.25 5.1875C10.3125 4.84375 10.625 4.5625 11 4.5625C11.4062 4.5625 11.75 4.875 11.75 5.28125V8.9375C11.75 9.6875 12.3438 10.3125 13.125 10.3125C13.875 10.3125 14.5 9.6875 14.5 8.9375V8.34375C14.5 4.9375 11.875 2.125 8.65625 2.03125C7 1.96875 5.5 2.5625 4.3125 3.71875C3.125 4.84375 2.5 6.40625 2.5 8.0625C2.5 11.0312 4.6875 13.5938 7.59375 14.0312C8 14.0938 8.28125 14.4688 8.21875 14.875C8.15625 15.25 7.84375 15.5 7.5 15.5C7.4375 15.5 7.40625 15.5 7.375 15.5C3.71875 14.9688 1 11.75 1 8.0625C1 6 1.8125 4.0625 3.28125 2.625C4.75 1.21875 6.6875 0.46875 8.71875 0.53125ZM8.25 10C9.34375 10 10.25 9.125 10.25 8.03125C10.25 6.96875 9.34375 6.0625 8.25 6.0625C7.125 6.0625 6.25 6.9375 6.25 8.03125C6.25 9.125 7.125 10 8.25 10Z"
      fill="currentColor"
    />
  </svg>
);
