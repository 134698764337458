import { Icon } from "@chakra-ui/react";

export const CalendarSVGIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 2.5C14.0938 2.5 15 3.40625 15 4.5V14.5C15 15.625 14.0938 16.5 13 16.5H3C1.875 16.5 1 15.625 1 14.5V4.5C1 3.40625 1.875 2.5 3 2.5H4.25V1.25C4.25 0.84375 4.5625 0.5 5 0.5C5.40625 0.5 5.75 0.84375 5.75 1.25V2.5H10.25V1.25C10.25 0.84375 10.625 0.5 11 0.5C11.4062 0.5 11.75 0.84375 11.75 1.25V2.5H13ZM13 15C13.25 15 13.5 14.7812 13.5 14.5V6.5H2.5V14.5C2.5 14.7812 2.71875 15 3 15H13Z"
      fill="currentColor"
    />
  </svg>
);
