export const MyStatsSVGIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 13.25C1.5 13.6875 1.8125 14 2.25 14H15.25C15.6562 14 16 14.3438 16 14.75C16 15.1875 15.6562 15.5 15.25 15.5H2.25C1 15.5 0 14.5 0 13.25V2.25C0 1.84375 0.3125 1.5 0.75 1.5C1.15625 1.5 1.5 1.84375 1.5 2.25V13.25ZM10.5 10.0312C10.2188 10.3438 9.75 10.3438 9.46875 10.0312L6.96875 7.5625L4.5 10.0312C4.21875 10.3438 3.75 10.3438 3.46875 10.0312C3.15625 9.75 3.15625 9.28125 3.46875 8.96875L6.46875 5.96875C6.59375 5.84375 6.78125 5.75 6.96875 5.75C7.1875 5.75 7.375 5.84375 7.5 5.96875L10 8.46875L13.7188 4.71875C14 4.4375 14.4688 4.4375 14.75 4.71875C15.0625 5.03125 15.0625 5.5 14.75 5.78125L10.5 10.0312Z"
      fill="currentColor"
    />
  </svg>
);
