import {
  Box,
  Flex,
  FlexProps,
  HStack,
  Img,
  Image,
  useMenuButton,
  Heading,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import * as React from "react";
import { HiSelector, HiMenuAlt3 } from "react-icons/hi";
import { FiMoreVertical } from "react-icons/fi";
import { layerStyle, textStyle } from "theme/names";
import { getAccount } from "utils/sessionHelper";
import { useSession } from "next-auth/client";
import { useDisclosure } from "@chakra-ui/react-use-disclosure";


export const AccountSwitcherButton = (props: FlexProps & any) => {
  let {disableMenu, disablePhoto, navSize, ...rest} = props;
  const buttonProps = useMenuButton(rest);
  const [session, loading] = useSession();
  // console.log(props.user);
  const {
    isOpen: isOpenGhostData,
    onOpen: onOpenGhostData,
    onClose: onCloseGhostData,
  } = useDisclosure();

  const [buttonHover, buttonHoverSet] = React.useState(false);

  const standardColor = useColorModeValue(
    "text.lightMode.standard",
    "text.darkMode.standard"
  );
  const lightColor = useColorModeValue(
    "text.lightMode.light",
    "text.darkMode.light"
  );

  return (
    <Flex
      as="button"
      {...buttonProps}
      display="flex"
      w="full"
      alignItems="center"
      rounded="lg"
      p="2"
      fontSize="sm"
      userSelect="none"
      cursor="pointer"
      outline="0"
      transition="all 0.2s"
      layerStyle={layerStyle["bg.border.rounded"]}
      onMouseEnter={() => buttonHoverSet(true)}
      onMouseLeave={() => buttonHoverSet(false)}
    >
      {!disablePhoto && (
        <HStack flex="1" spacing="3">
          <Img
            w="10"
            h="10"
            rounded="3xl"
            objectFit="cover"
            src={props?.user?.image}
            alt="Chakra UI"
            mr={2}
          />
        </HStack>
      )}
      <Flex
        h="40px"
        justifyContent={navSize == "large" ? "flex-start" : "center"}
        flex={1}
      >
        <Image
          w="10"
          h="10"
          rounded="3xl"
          objectFit="cover"
          src={getAccount(session)?.image}
          fallbackSrc="/assets/resources/emptyProfile.png"
          ignoreFallback={false}
          onClick={() => {
            if (
              (session?.user?.data?.isGhost || session?.user?.data?.isAdmin) &&
              getAccount(session)?.ghostData?.length
            ) {
              onOpenGhostData();
            }
          }}
        />
        <Flex
          display={navSize == "small" ? "none" : "flex"}
          flexDirection="column"
          ml={2}
          textAlign="start"
          flex={1}
        >
          <Box
            noOfLines={1}
            wordBreak="break-all"
            w="140px"
            as="h3"
            textStyle={textStyle["body.bold.standard"]}
          >
            {getAccount(session)?.name}
          </Box>
          {getAccount(session)?.twUserName && (
            <Box
              noOfLines={1}
              wordBreak="break-all"
              w="140px"
              textStyle={textStyle["body.medium.light"]}
            >
              @{getAccount(session)?.twUserName}
            </Box>
          )}
        </Flex>
      </Flex>
      {!props.disableMenu && (
         <Box fontSize="md" color={buttonHover ? standardColor : lightColor}>
         {/* <FiMoreHorizontal /> */}
         <svg
           width="12"
           height="13"
           viewBox="0 0 12 13"
           fill="none"
           xmlns="http://www.w3.org/2000/svg"
         >
           <path
             d="M2.88281 5.75H9.09375C9.65625 5.75 9.96094 5.07031 9.53906 4.64844L6.44531 1.46094C6.32812 1.32031 6.16406 1.25 6 1.25C5.83594 1.25 5.64844 1.32031 5.53125 1.46094L2.4375 4.64844C2.01562 5.07031 2.32031 5.75 2.88281 5.75ZM9.09375 7.25H2.88281C2.32031 7.25 2.01562 7.95312 2.4375 8.375L5.53125 11.5625C5.64844 11.7031 5.8125 11.75 6 11.75C6.16406 11.75 6.32812 11.7031 6.44531 11.5625L9.53906 8.375C9.96094 7.95312 9.65625 7.25 9.09375 7.25Z"
             fill="currentColor"
           />
         </svg>
       </Box>
      )}
    </Flex>
  );
};
