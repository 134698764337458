import { Icon } from "@chakra-ui/react";

export const ContactCardSVGIcon = (
  <svg
    width="17"
    height="17"
    viewBox="0 0 19 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 8C5.875 8 5 7.125 5 6C5 4.90625 5.875 4 7 4C8.09375 4 9 4.90625 9 6C9 7.125 8.09375 8 7 8ZM15 7.25C15.4062 7.25 15.75 7.59375 15.75 8C15.75 8.4375 15.4062 8.75 15 8.75H12C11.5625 8.75 11.25 8.4375 11.25 8C11.25 7.59375 11.5625 7.25 12 7.25H15ZM8 9C9.375 9 10.5 10.125 10.5 11.5C10.5 11.7812 10.25 12 10 12H4C3.71875 12 3.5 11.7812 3.5 11.5C3.5 10.125 4.59375 9 6 9H8ZM15 4.75C15.4062 4.75 15.75 5.09375 15.75 5.5C15.75 5.9375 15.4062 6.25 15 6.25H12C11.5625 6.25 11.25 5.9375 11.25 5.5C11.25 5.09375 11.5625 4.75 12 4.75H15ZM16.5 1C17.5938 1 18.5 1.90625 18.5 3V13C18.5 14.125 17.5938 15 16.5 15H2.5C1.375 15 0.5 14.125 0.5 13V3C0.5 1.90625 1.375 1 2.5 1H16.5ZM17 13V3C17 2.75 16.75 2.5 16.5 2.5H2.5C2.21875 2.5 2 2.75 2 3V13C2 13.2812 2.21875 13.5 2.5 13.5H16.5C16.75 13.5 17 13.2812 17 13Z"
      fill="currentColor"
    />
  </svg>
);
