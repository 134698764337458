import { Icon } from "@chakra-ui/react";

export const LibrarySVGIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.25 13H14V15H14.25C14.6562 15 15 15.3438 15 15.75C15 16.1875 14.6562 16.5 14.25 16.5H3.5C2.09375 16.5 1 15.4062 1 14V3C1 1.625 2.09375 0.5 3.5 0.5H14.25C14.6562 0.5 15 0.84375 15 1.25V12.25C15 12.6875 14.6562 13 14.25 13ZM12.5 15V13H3.5C2.9375 13 2.5 13.4688 2.5 14C2.5 14.5625 2.9375 15 3.5 15H12.5ZM13.5 11.5V2H3.5C2.9375 2 2.5 2.46875 2.5 3V11.7188C2.78125 11.5938 3.125 11.5 3.5 11.5H13.5ZM5.75 5.5C5.3125 5.5 5 5.1875 5 4.75C5 4.34375 5.3125 4 5.75 4H11.25C11.6562 4 12 4.34375 12 4.75C12 5.1875 11.6562 5.5 11.25 5.5H5.75ZM5.75 8C5.3125 8 5 7.6875 5 7.25C5 6.84375 5.3125 6.5 5.75 6.5H11.25C11.6562 6.5 12 6.84375 12 7.25C12 7.6875 11.6562 8 11.25 8H5.75Z"
      fill="currentColor"
    />
  </svg>
);
