import { Icon } from "@chakra-ui/react";

export const SearchModeSVGIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.75 15.2188L11.5625 11.0312C12.4688 9.9375 12.9688 8.53125 12.9688 7C12.9688 3.4375 10.0312 0.5 6.46875 0.5C2.875 0.5 0 3.4375 0 7C0 10.5938 2.90625 13.5 6.46875 13.5C7.96875 13.5 9.375 13 10.5 12.0938L14.6875 16.2812C14.8438 16.4375 15.0312 16.5 15.25 16.5C15.4375 16.5 15.625 16.4375 15.75 16.2812C16.0625 16 16.0625 15.5312 15.75 15.2188ZM1.5 7C1.5 4.25 3.71875 2 6.5 2C9.25 2 11.5 4.25 11.5 7C11.5 9.78125 9.25 12 6.5 12C3.71875 12 1.5 9.78125 1.5 7Z"
      fill="currentColor"
    />
  </svg>
);
