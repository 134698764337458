export const ProfileAutomationsSVGIcon = (
  <svg width="15" height="15" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.40365 13.1097H1.83622C1.80171 13.1097 1.7685 13.0954 1.74441 13.0713C1.71966 13.0465 1.70599 13.014 1.70599 12.9795V12.0346C1.70599 11.4942 2.09213 11.0175 2.68729 10.6125C3.74998 9.88709 5.4697 9.42997 7.40365 9.42997C7.67323 9.42997 7.89202 9.21118 7.89202 8.9416C7.89202 8.67267 7.67323 8.45323 7.40365 8.45323C5.24049 8.45323 3.32543 8.99435 2.13641 9.80504C1.22934 10.4236 0.729248 11.2096 0.729248 12.0346V12.9795C0.729248 13.2725 0.845806 13.5544 1.05353 13.7621C1.26125 13.9692 1.54255 14.0864 1.83622 14.0864H7.40365C7.67323 14.0858 7.89202 13.867 7.89202 13.5974C7.89202 13.3285 7.67323 13.109 7.40365 13.1097Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M7.40365 0.729156C5.42672 0.729156 3.82226 2.33362 3.82226 4.31054C3.82226 6.28747 5.42672 7.89193 7.40365 7.89193C9.38057 7.89193 10.985 6.28747 10.985 4.31054C10.985 2.33362 9.38057 0.729156 7.40365 0.729156ZM7.40365 1.7059C8.84141 1.7059 10.0083 2.87278 10.0083 4.31054C10.0083 5.7483 8.84141 6.91519 7.40365 6.91519C5.96588 6.91519 4.799 5.7483 4.799 4.31054C4.799 2.87278 5.96588 1.7059 7.40365 1.7059Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M11.3107 8.99109C10.0305 8.99109 8.99122 10.0303 8.99122 11.3105C8.99122 12.5907 10.0305 13.6306 11.3107 13.6306C12.5908 13.6306 13.6307 12.5907 13.6307 11.3105C13.6307 10.0303 12.5908 8.99109 11.3107 8.99109ZM11.3107 9.96783C12.0517 9.96783 12.654 10.5695 12.654 11.3105C12.654 12.0515 12.0517 12.6539 11.3107 12.6539C10.5696 12.6539 9.96731 12.0515 9.96731 11.3105C9.96731 10.5695 10.5696 9.96783 11.3107 9.96783Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M11.799 9.47881V8.3803C11.799 8.11072 11.5802 7.89193 11.3106 7.89193C11.041 7.89193 10.8222 8.11072 10.8222 8.3803V9.47946C10.8222 9.74839 11.0411 9.96783 11.3107 9.96783C11.5802 9.96718 11.799 9.74839 11.799 9.47881Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.9509 10.3611L13.7277 9.58365C13.9185 9.39351 13.9185 9.08355 13.7277 8.89342C13.5376 8.70263 13.2276 8.70263 13.0375 8.89342L12.26 9.67025C12.0699 9.86104 12.0699 10.1703 12.26 10.3611C12.4508 10.5513 12.7601 10.5513 12.9509 10.3611Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M13.1417 11.7989H14.2409C14.5105 11.7989 14.7292 11.5801 14.7292 11.3105C14.7292 11.0409 14.5105 10.8222 14.2409 10.8222H13.1417C12.8728 10.8222 12.654 11.0409 12.654 11.3105C12.6533 11.5801 12.8728 11.7989 13.1417 11.7989Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.26 12.9508L13.0375 13.7276C13.2276 13.9184 13.5376 13.9184 13.7277 13.7276C13.9185 13.5375 13.9185 13.2275 13.7277 13.0374L12.9509 12.2599C12.7601 12.0698 12.4508 12.0698 12.26 12.2599C12.0699 12.4507 12.0699 12.76 12.26 12.9508Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.8222 13.1416V14.2407C10.8222 14.5103 11.041 14.7291 11.3106 14.7291C11.5802 14.7291 11.799 14.5103 11.799 14.2407V13.1416C11.799 12.8727 11.5802 12.6545 11.3107 12.6539C11.0411 12.6539 10.8222 12.8727 10.8222 13.1416Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.67032 12.2599L8.89348 13.0374C8.70269 13.2275 8.70269 13.5375 8.89348 13.7276C9.08362 13.9184 9.39358 13.9184 9.58371 13.7276L10.3612 12.9508C10.5513 12.76 10.5513 12.4507 10.3612 12.2599C10.1704 12.0698 9.86111 12.0698 9.67032 12.2599Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.4789 10.8222H8.38039C8.11081 10.8222 7.89202 11.0409 7.89202 11.3105C7.89202 11.5801 8.11081 11.7989 8.38039 11.7989H9.4789C9.74848 11.7989 9.96796 11.5801 9.96731 11.3105C9.96731 11.0409 9.74848 10.8222 9.4789 10.8222Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.3612 9.67025L9.58371 8.89342C9.39358 8.70263 9.08362 8.70263 8.89348 8.89342C8.70269 9.08355 8.70269 9.39351 8.89348 9.58365L9.67032 10.3611C9.86111 10.5513 10.1704 10.5513 10.3612 10.3611C10.5513 10.1703 10.5513 9.86104 10.3612 9.67025Z" fill="currentColor" />
  </svg>
);
