import {
  Menu,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  useColorModeValue,
  useColorMode,
  Center,
  Switch,
  Stack,
  Flex,
  Box,
  Button,
  Img,
  Image,
  Heading,
  Text,
  Input,
  FormControl,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import Payment from "components/payment";
import SubscriptionManagement from "components/subscriptionManagement";
import * as React from "react";
import { AccountSwitcherButton } from "./account-switcher-button";
import { signOut } from "next-auth/client";
import { useRouter } from "next/dist/client/router";
import { useSession } from "next-auth/client";
import { FiUnlock } from "react-icons/fi";
import { ImCross } from "react-icons/im";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import * as analytics from "../../utils/analytics";
import { getPlan } from "../../controllers/subscription";
import { TweetContext } from "../../context/tweetContext";
import { firebaseClient, getToken } from "firebaseClient";
import toast from "react-hot-toast";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useFirebaseUser } from "../../utils/useFirebaseUser";
import { setInLocalStorage } from "../../utils/helpers";
import { getAccount, updateUser, getAuthUrl, selectAccount, logout } from "utils/sessionHelper";
import { ConfirmPopup } from "../popups/confirmPopup";
import { GiSentryGun } from "react-icons/gi";
import * as Sentry from "@sentry/nextjs";
import { AddAccount } from "components/popups/add-account";
import { layerStyle, textStyle } from "theme/names";
import { NavSectionTitle } from "components/sidebar/mainNavItems";
import { HiOutlineLogout } from "react-icons/hi";

const async = require('async');

function AccountSwitcherMenuItem({ onClick, label, icon }) {
  return (
    <MenuItem
      onClick={() => {
        // onOpenConfirmAdd();
        // props.push("/settings?tab=team");
        onClick();
      }}
      rounded="md"
      my={0}
      py={2}
      px={1}
      {...{
        color: "text.lightMode.light",
        _hover: {
          color: "text.lightMode.standard",
          bg: "none",
        },
        _dark: {
          color: "text.darkMode.light",
          bg: "none",
          _hover: {
            color: "text.darkMode.standard",
          },
        },
      }}
      icon={icon}
      // icon={<AiOutlinePlusCircle fontSize="16px" />}
    >
      <Text fontSize="sm" fontWeight={500}>
        {label}
      </Text>
    </MenuItem>
  );
}

export const AccountSwitcher = ({ user, disableMenu, disablePhoto=false }) => {
  const [session, loading] = useSession();
  const { colorMode, toggleColorMode } = useColorMode();
  const tweetContext: any = React.useContext(TweetContext);
  const [customSearch, setCustomSearch] = React.useState("");
  const [initialAccounts, setInitialAccounts] = React.useState<any>([]);
  const isAuthenticated = useFirebaseUser();
  const [refresh, refresher] = React.useState({});
  
  const [selectedAccount, setSelectedAccount] = React.useState<any>({});
  const { isOpen: isOpenConfirmDelete, onOpen: onOpenConfirmDelete, onClose: onCloseConfirmDelete } = useDisclosure();
  const { isOpen: isOpenConfirmAdd, onOpen: onOpenConfirmAdd, onClose: onCloseConfirmAdd } = useDisclosure();

  const router = useRouter();

  let accounts = session?.user?.data?.accounts?.filter(x => x.idAccount != session.user.uid && x.provider == "twitter");

  React.useEffect(() => {
    if (isAuthenticated && accounts) {
      accounts?.forEach(async (acc) => {
        if (acc.needInit && acc.id !== session?.user?.uid) {
          selectAccount(session, acc, "owned");
          acc.needInit = false;
          const db = firebaseClient.firestore();
          await db.collection("users").doc(session?.user?.uid).collection("accounts").doc(acc.id).update({needInit: false});
          router.push("/onboarding");
        }
        else if (acc.needInit) {
          const db = firebaseClient.firestore();
          await db.collection("users").doc(session?.user?.uid).collection("accounts").doc(acc.id).update({needInit: false});
        }
      })
    }
  },[isAuthenticated, session]);


  const getItem = (acc, session, type="shared") => {
    return (
      <MenuItem
        key={"acc-" + acc.id}
        as="div"
        position="relative"
        onClick={async (e) => {
          // console.log(acc);
          if (acc.id === session.user.uid) {
            console.log("going to main account");
            session.user.selectedAccount = undefined;
            session.user.selectedAccountId = "";
            session.user.selectedAccountType = "";
            localStorage.removeItem("selectedAccountId");
            localStorage.removeItem("selectedAccount");
            localStorage.removeItem("selectedAccountType");
          } else {
            selectAccount(session, acc, type);
          }

          // console.log("local selectedAccountId: " + localStorage.getItem("selectedAccountId"));
          // return;

          // tweetContext.open();
          // tweetContext.newTweet("");
          // //@ts-ignore
          // refresher && refresher({});

          if (acc.needInit) {
            const db = firebaseClient.firestore();
            await db
              .collection("users")
              .doc(session?.user?.uid)
              .collection("accounts")
              .doc(acc.id)
              .update({ needInit: false });
            router.push("/onboarding");
          } else location.reload();
        }}
        borderRadius="8px"
        mb={2}
        py={1}
        px={1}
        bg="none"
        border={"1px solid"}
        borderColor={"border.lightMode.light"}
        _hover={{
          cursor: "pointer",
          borderColor: "border.lightMode.hover",
        }}
        _dark={{
          borderColor: "border.darkMode.light",
          _hover: {
            borderColor: "border.darkMode.hover",
            bg: "none",
          },
        }}
      >
        {["owned", "shared"].includes(type) &&
          !acc?.isFromOrg &&
          acc.id !== session?.user?.uid && (
            <IconButton
              size="xs"
              aria-label="delete account"
              icon={<ImCross fontSize="8px" />}
              position="absolute"
              right="5px"
              top="5px"
              zIndex="10"
              onClick={async (e) => {
                console.log("delete account");
                e.stopPropagation();
                setSelectedAccount(acc);
                onOpenConfirmDelete();
              }}
            />
          )}
        <Image
          w="10"
          h="10"
          rounded="3xl"
          objectFit="cover"
          src={acc.image}
          fallbackSrc="/assets/resources/emptyProfile.png"
        />
        <Flex flexDirection="column" ml={2}>
          <Box
            noOfLines={1}
            wordBreak="break-all"
            w="130px"
            as="h3"
            textStyle={textStyle["body.bold.standard"]}
          >
            {acc.name}
          </Box>
          {acc.twUserName && (
            <Box
              noOfLines={1}
              wordBreak="break-all"
              w="130px"
              // color={useColorModeValue("gray.400", "gray.300")}
              textStyle={textStyle["body.medium.light"]}
            >
              @{acc.twUserName}
            </Box>
          )}
        </Flex>
      </MenuItem>
    );
  }

  const bg = useColorModeValue("white", "#1E1E1E");

  return (
    <Menu
      // offset={[0, 0]}
      placement="top"
    >
      <AccountSwitcherButton
        user={user}
        disableMenu={disableMenu}
        disablePhoto={disablePhoto}
      />
      {!disableMenu && (
        // full witdh menu to prevent visual displacement of menu on hover when transitionning from navSize === "small" to navSize === "large"
        <MenuList
          background="none"
          border="none"
          shadow="none"
          mb={1}
          // maxH="700px"
          // overflowY="auto"
          minW={"calc(250px - 0px)"}
          maxH="80vh"
          overflowY="auto"
        >
          {/* the box is the actual visible menu (prevents visual displacement of menu on hover when transitionning from navSize === "small" to navSize === "large") */}
          <Box
            layerStyle={layerStyle["bg.border.rounded"]}
            mb={1}
            fontSize={"sm"}
            color={"text.lightMode.light"}
            _dark={{
              color: "text.darkMode.light",
            }}
            bg={bg}
            shadow="2xl"
            p="2"
            ml={2}
            borderRadius={"md"}
            margin="auto"
            maxW={"calc(250px - 18px)"}
            maxH="70vh"
            overflowY="auto"
            // overflowY="auto"
          >
            {getItem(session?.user?.data, session)}
            {accounts?.length > 0 && (
              <>
                {/* <Text fontWeight="600" mt={5}>
                  Your accounts
                </Text> */}
                <NavSectionTitle
                  {...{
                    title: "YOUR ACCOUNTS",
                    navSize: "large",
                    compact: true,
                  }}
                ></NavSectionTitle>
                {accounts?.map((acc) => getItem(acc, session, "owned"))}
              </>
            )}
            {/* <MenuItem 
              onClick={() => {
                if (session?.user?.uid == getAccount(session)?.idParent || session?.user?.uid == getAccount(session)?.id)
                  onOpenConfirmAdd();
                else
                  toast.error("You can only do that from an account you own. Ask the owner of this account to do it.")
              }}
              rounded="md"
              mb={2}
            >
              <AiOutlinePlusCircle fontSize="20px" />
              <Text pl={2}>Add new account</Text>
              </MenuItem> */}
            <AccountSwitcherMenuItem
              {...{
                onClick: () => {
                  if (
                    session?.user?.uid == getAccount(session)?.idParent ||
                    session?.user?.uid == getAccount(session)?.id
                  )
                    onOpenConfirmAdd();
                  else
                    toast.error(
                      "You can only do that from an account you own. Ask the owner of this account to do it."
                    );
                },
                label: "Add new account",
                icon: <AiOutlinePlusCircle fontSize="16px" />,
              }}
            ></AccountSwitcherMenuItem>
            {session?.user?.linkAccounts?.filter((x) => !x.isFromOrg)?.length >
              0 && (
              <>
                {/* <Text fontWeight="600" mt={3} mb={1}>
                  Accounts shared
                </Text> */}
                <NavSectionTitle
                  {...{
                    title: "ACCOUNTS SHARED",
                    navSize: "large",
                    compact: true,
                  }}
                ></NavSectionTitle>
                {session?.user?.linkAccounts
                  ?.filter((x) => !x.isFromOrg)
                  ?.map((acc) => getItem(acc, session))}
              </>
            )}
            {session?.user?.linkAccounts?.filter((x) => x.isFromOrg)?.length >
              0 && (
              <>
                {/* <Text fontWeight="600" mt={3} mb={1}>
                  Your Organization
                </Text> */}
                <NavSectionTitle
                  {...{
                    title: "YOUR ORGANIZATION",
                    navSize: "large",
                    compact: true,
                  }}
                ></NavSectionTitle>
                {session?.user?.linkAccounts
                  ?.filter((x) => x.isFromOrg)
                  ?.map((acc) => getItem(acc, session))}
              </>
            )}
            {session?.user?.data.isAdmin && (
              <Flex w="100%">
                <Input
                  placeholder="@handle or email"
                  value={customSearch}
                  onChange={(e) => {
                    setCustomSearch(e.target.value);
                  }}
                />
                <Button
                  ml={2}
                  variant="secondary"
                  onClick={async () => {
                    console.log("look for " + customSearch);
                    toast.promise(
                      new Promise(async (resolve, reject) => {
                        if (session?.user?.data?.impersonatingOriginUser?.id) {
                          reject(
                            "You can't impersonate another user while impersonating another user"
                          );
                          return;
                        }

                        let response = await fetch("/api/impersonate/start", {
                          method: "POST",
                          headers: { "Content-Type": "application/json" },
                          body: JSON.stringify({
                            tokenUserId: session.user.uid,
                            token: await getToken(session, "checkTweetValid"),
                            userToImpersonate: customSearch,
                          }),
                        });
                        let data = await response.json();

                        if (data?.success) {
                          location.reload();
                          resolve(user);
                        } else {
                          reject(data.error ?? "An error occured");
                        }
                      }),
                      {
                        loading: "loading ... ",
                        success: "Success",
                        error: (err) =>
                          err ? err.toString() : "An error occured.",
                      }
                    );
                  }}
                >
                  Go
                </Button>
              </Flex>
            )}
            {
              // (session?.user?.linkAccounts?.length > 0 || session?.user?.data?.accounts?.length > 0) && (
              true && <MenuDivider />
            }
            {/* <MenuItem
              onClick={() => {
                logout();
              }}
              rounded="md"
            >
              Logout
            </MenuItem> */}
            <AccountSwitcherMenuItem
              {...{
                onClick: () => {
                  logout();
                },
                label: "Logout",
                icon: (
                  <HiOutlineLogout
                    fontSize="16px"
                    style={{ marginLeft: "4px", marginRight: "-2px" }}
                  />
                ),
              }}
            ></AccountSwitcherMenuItem>

            {session?.user?.data?.subscription?.isSubscribed || true ? (
              getPlan(session?.user) == "start" ? (
                <>
                  <MenuDivider />
                  <Button
                    mt={2}
                    onClick={() => {
                      analytics.log("hit_unlock", { source: "menu" });
                      router.push("/pricing");
                    }}
                    // colorScheme="twitter"
                    variant="secondary"
                    css={{
                      width: "100%",
                    }}
                  >
                    Upgrade
                  </Button>
                </>
              ) : (
                <></>
              )
            ) : (
              <>
                <MenuDivider />
                <Button
                  mt={2}
                  onClick={() => {
                    analytics.log("hit_unlock", { source: "menu" });
                    router.push("/pricing");
                  }}
                  // colorScheme="twitter"
                  variant="secondary"
                  css={{
                    width: "100%",
                  }}
                >
                  Start Free Trial
                </Button>
              </>
            )}
          </Box>
        </MenuList>
      )}
      <ConfirmPopup
        isOpen={isOpenConfirmDelete}
        onClose={onCloseConfirmDelete}
        title="Please confirm account removal"
        body={
          <>
            You’re about to remove the account:{" "}
            <b>
              {selectedAccount.twUserName
                ? "@" + selectedAccount.twUserName
                : selectedAccount.name}
            </b>
          </>
        }
        callback={async () => {
          console.log("selectedAccount:", selectedAccount);
          if (selectedAccount?.id) {
            if (selectedAccount.type == "shared") {
              let dataToSend = {
                idUser: selectedAccount.id,
                mode: "delete",
                idGhost: getAccount(session).id,
                tokenUserId: session?.user?.uid,
                token: await getToken(session, "sidebar-share-delete"),
              };
              let response = await fetch("/api/shareAccount", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(dataToSend),
              });
              let data = await response.json();
              if (data?.success && data.shareAccountsTo) {
                getAccount(session).shareAccountsTo = data.shareAccountsTo;
                refresher({});
                await new Promise((resolve) => setTimeout(resolve, 1000));
                location.reload();
              } else {
                toast.error("An error occured: " + data?.error);
              }
            } else {
              // if (selectedAccount.type == "owned") {
              const db = firebaseClient.firestore();
              await db
                .collection("users")
                .doc(session?.user?.uid)
                .collection("accounts")
                .doc(selectedAccount.id)
                .delete();
              let docTweets = await db
                .collection("users")
                .doc(session?.user?.uid)
                .collection("tweets")
                .where("idAccount", "==", selectedAccount.id)
                .get();
              console.log("nb tweets to delete: " + docTweets.docs?.length);
              // await async.mapLimit(docTweets.docs, 10, async (doc) => {
              docTweets.forEach((doc) => {
                console.log("delete tweets " + doc.id);
                db.collection("users")
                  .doc(session?.user?.uid)
                  .collection("tweets")
                  .doc(doc.id)
                  .delete();
              });
              await new Promise((resolve) => setTimeout(resolve, 1000));
              location.reload();
            }
            // refresher({});
          }
        }}
      />
      <AddAccount isOpen={isOpenConfirmAdd} onClose={onCloseConfirmAdd} />
    </Menu>
  );
};
