import { Icon } from "@chakra-ui/react";

export const SquareUserSVGIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 1C13.5938 1 14.5 1.90625 14.5 3V13C14.5 14.125 13.5938 15 12.5 15H2.5C1.375 15 0.5 14.125 0.5 13V3C0.5 1.90625 1.375 1 2.5 1H12.5ZM4.03125 13.5H10.9375C10.6875 12.375 9.6875 11.5 8.5 11.5H6.5C5.28125 11.5 4.28125 12.375 4.03125 13.5ZM13 13V3C13 2.75 12.75 2.5 12.5 2.5H2.5C2.21875 2.5 2 2.75 2 3V13C2 13.2812 2.21875 13.5 2.5 13.5H2.53125C2.78125 11.5312 4.4375 10 6.5 10H8.5C10.5312 10 12.1875 11.5312 12.4375 13.5H12.5C12.75 13.5 13 13.2812 13 13ZM7.5 3.5C9 3.5 10.25 4.75 10.25 6.25C10.25 7.78125 9 9 7.5 9C5.96875 9 4.75 7.78125 4.75 6.25C4.75 4.75 5.96875 3.5 7.5 3.5ZM7.5 7.5C8.1875 7.5 8.75 6.96875 8.75 6.25C8.75 5.5625 8.1875 5 7.5 5C6.78125 5 6.25 5.5625 6.25 6.25C6.25 6.96875 6.78125 7.5 7.5 7.5Z"
      fill="currentColor"
    />
  </svg>
);
