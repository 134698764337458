import { useSession } from "next-auth/client";
import { ConfirmPopup } from "./confirmPopup";
import * as analytics from "utils/analytics";
import { getAuthUrl } from "utils/sessionHelper";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export function AddAccount({ isOpen, onClose }: Props) {
  const [session] = useSession();

  return (
    <ConfirmPopup
      isOpen={isOpen}
      onClose={onClose}
      title="Add a new account"
      body={
        "Please, make sure you are signed in on Twitter with the account you want to add"
      }
      cta="Connect"
      ctaProps={{
        as: "a",
        href: getAuthUrl(session),
        target: "_blank",
      }}
      callback={async () => {
        analytics.log("hit_add_account");
      }}
    />
  );
}
