export const GeneratorsAIExpandedSVGIcon = (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient
        id="paint0_linear_309_1148"
        x1="0"
        y1="0"
        x2="20"
        y2="30"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EC6181" />
        <stop offset="1" stopColor="#316BFF" />
      </linearGradient>
    </defs>
    <path
      d="M15.5 9C15.75 9 16 8.78125 16 8.5C16 8.25 15.75 8 15.5 8H14V6H15.5C15.75 6 16 5.78125 16 5.5C16 5.25 15.75 5 15.5 5H14V4.5C14 3.40625 13.0938 2.5 12 2.5H11.5V1C11.5 0.75 11.25 0.5 11 0.5C10.7188 0.5 10.5 0.75 10.5 1V2.5H8.5V1C8.5 0.75 8.25 0.5 8 0.5C7.71875 0.5 7.5 0.75 7.5 1V2.5H5.5V1C5.5 0.75 5.25 0.5 5 0.5C4.71875 0.5 4.5 0.75 4.5 1V2.5H4C2.875 2.5 2 3.40625 2 4.5V5H0.5C0.21875 5 0 5.25 0 5.5C0 5.78125 0.21875 6 0.5 6H2V8H0.5C0.21875 8 0 8.25 0 8.5C0 8.78125 0.21875 9 0.5 9H2V11H0.5C0.21875 11 0 11.25 0 11.5C0 11.7812 0.21875 12 0.5 12H2V12.5C2 13.625 2.875 14.5 4 14.5H4.5V16C4.5 16.2812 4.71875 16.5 5 16.5C5.25 16.5 5.5 16.2812 5.5 16V14.5H7.5V16C7.5 16.2812 7.71875 16.5 8 16.5C8.25 16.5 8.5 16.2812 8.5 16V14.5H10.5V16C10.5 16.2812 10.7188 16.5 11 16.5C11.25 16.5 11.5 16.2812 11.5 16V14.5H12C13.0938 14.5 14 13.625 14 12.5V12H15.5C15.75 12 16 11.7812 16 11.5C16 11.25 15.75 11 15.5 11H14V9H15.5ZM8.4375 10.9688C8.375 11 8.3125 11 8.25 11C8.03125 11 7.84375 10.9062 7.78125 10.7188L7.46875 10H5.5L5.1875 10.7188C5.09375 10.9688 4.78125 11.0938 4.53125 10.9688C4.28125 10.875 4.15625 10.5625 4.28125 10.3125L6.03125 6.3125C6.1875 5.9375 6.78125 5.9375 6.9375 6.3125L8.6875 10.3125C8.8125 10.5625 8.6875 10.875 8.4375 10.9688ZM11 10.5C11 10.7812 10.75 11 10.5 11C10.2188 11 10 10.7812 10 10.5V6.5C10 6.25 10.2188 6 10.5 6C10.75 6 11 6.25 11 6.5V10.5ZM5.9375 9H7.03125L6.5 7.75L5.9375 9Z"
      fill="url(#paint0_linear_309_1148)"
    />
  </svg>
);
