import { Icon } from "@chakra-ui/react";

export const PlaySVGIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 13 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.25 1.21875C1.71875 0.9375 2.3125 0.9375 2.78125 1.25L11.7812 6.71875C12.2188 7 12.5 7.5 12.5 8C12.5 8.53125 12.2188 9.03125 11.7812 9.28125L2.78125 14.7812C2.3125 15.0625 1.71875 15.0938 1.25 14.8125C0.78125 14.5625 0.5 14.0625 0.5 13.5V2.5C0.5 1.96875 0.78125 1.46875 1.25 1.21875ZM2 13.5L11 8L2 2.5V13.5Z"
      fill="currentColor"
    />
  </svg>
);
