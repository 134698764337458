import { Icon } from "@chakra-ui/react";

export const StarSVGIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.63541 0C8.94791 0 9.19791 0.1875 9.32291 0.4375L11.4792 4.84375L16.2604 5.5625C16.5417 5.59375 16.7604 5.8125 16.8542 6.0625C16.9479 6.34375 16.8854 6.625 16.6667 6.84375L13.1979 10.2812L14.0104 15.125C14.0729 15.4062 13.9479 15.7188 13.7292 15.875C13.4792 16.0312 13.1667 16.0625 12.9167 15.9375L8.63541 13.625L4.35416 15.9375C4.10416 16.0625 3.82291 16.0312 3.57291 15.875C3.35416 15.7188 3.22916 15.4062 3.26041 15.125L4.10416 10.2812L0.635406 6.84375C0.416656 6.625 0.354156 6.34375 0.447906 6.0625C0.541656 5.8125 0.760406 5.59375 1.04166 5.5625L5.82291 4.84375L7.97916 0.4375C8.10416 0.1875 8.35416 0 8.63541 0ZM8.63541 2.46875L7.01041 5.875C6.88541 6.09375 6.69791 6.25 6.44791 6.28125L2.72916 6.8125L5.41666 9.46875C5.60416 9.65625 5.66666 9.90625 5.63541 10.1562L5.01041 13.875L8.29166 12.125C8.51041 12 8.79166 12 9.01041 12.125L12.2917 13.875L11.6667 10.1562C11.6042 9.90625 11.6979 9.65625 11.8854 9.46875L14.5417 6.8125L10.8542 6.28125C10.6042 6.25 10.3854 6.09375 10.2917 5.875L8.63541 2.46875Z"
      fill="currentColor"
    />
  </svg>
);
